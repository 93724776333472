/**
 * 账户路由定义
 */

export const accountRouting = () => {
  return {
    /** 账号信息 */
    accountInformation: '/account/information',
    /** 免费使用 */
    accountFreeToUse: '/account/free-to-use',
    /** 设备列表 */
    accountListDevices: '/account/list-devices',
    /** 充值记录 */
    accountHistory: '/account/history',
    /** 帮助中心 */
    accountHelpVenter: '/help-center',
    /** 修改密码 */
    accountChangePassword: '/account/change-password',
    /** 重制邮箱 */
    remakeMailboxes: '/account/information/remake-mailboxes',
    /** 隐私协议 */
    privacyAgreement: '/help-center/help-center-text',
  }
}

export const getCreatePath = () => {
  return '/register/create-account'
}

/** 注销账号 */
export const logOut = () => {
  return '/account/information/log-out'
}

/** 登录界面 */
export const logIn = () => {
  return '/login'
}
